.similar-units-v1 {


	.similar-units-tabs {
		margin-bottom: 10px;


		.tab {

			display: inline-block;
			padding: 3px 15px;
			background-color: var(--color-secondary);
			color: var(--color-white);
			cursor: pointer;
			border-top-left-radius: 5px;
			border-top-right-radius: 5px;

			@media(max-width: 560px) {
				font-size: var(--font-size-md);
				padding: 3px 10px;
			}

			&.active {
				background-color: var(--color-primary);
			}
		}

		.desktop {
			display: none;

			@media(min-width: 561px) {
				display: block;
			}
		}

		.mobile {
			display: none;


			@media(max-width: 560px) {
				display: block;
			}
		}
	}

	.similar-units {

		position: relative;
		height: 340px;



		#similarsection1 {
			z-index: 3;
			visibility: visible;
		}

		#similarsection2 {

			visibility: hidden;
			z-index: 2;
		}

		#similarsection3 {
			visibility: hidden;
			z-index: 1;
		}
	}
}