.testpage {
	margin-top: var(--page-margin-top);

	.image {
		@media(max-width: 767px) {
			margin-bottom: 20px;
		}

		img {
			width: 100%;
			height: auto;
		}
	}
}