.unit-room-details-v1 {
	.unit-room-details-bl {
		padding: 15px;
		border: 1px solid #000;
		border-radius: 5px;
		height: 100%;

		.room {
			font-weight: 700;

			@media (max-width:992px) {
				font-size: var(--font-size-md);
			}

		}

		.room-details {
			ul {

				li {
					padding-left: 30px;
					position: relative;
					font-size: 14px;

					&:before {
						content: "";
						background-image: url(RESOURCE/img/icon-bad.svg);
						display: inline-block;
						background-repeat: no-repeat;
						background-position: center;
						background-size: contain;
						width: 25px;
						height: 28px;
						display: inline-block;
						position: absolute;
						top: 0;
						left: 0;
						margin-bottom: 10px;
					}
				}
			}
		}
	}


}