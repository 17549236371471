.static-v1 {
	padding-top: var(--page-margin-top);

	.blog-enteries {
		margin-bottom: 30px;

		.blog-block {
			.image-blog {
				height: 310px;
				width: 100%;
				display: block;
				overflow: hidden;

				img {

					object-fit: cover;
					border: none;
					padding: 0;
					border-radius: 0;
					width: 100%;
					height: 100%;
				}
			}


		}

		.lt-bl-cont {
			padding: 30px;
			background-color: var(--color-grey-normal);

			h2 {
				font-size: 24px;
			}

			p {
				font-size: 16px;
			}
		}
	}

	.inner-banner {
		/* &.angebote {
			background-image: url(RESOURCE/img/inner-banner-angebote.jpg);
		}
		&.reiseversicherung {
			background-image: url(RESOURCE/img/inner-banner-reiseversicherung.jpg);
		}
     
	&.fur-vermieter {
			background-image: url(RESOURCE/img/inner-banner-fur-vermieter.jpg);
		}

		&.greetsiel {
			background-image: url(RESOURCE/img/inner-banner-greetsiel.jpg);
		}

		&.schreiben-sie-uns {
			background-image: url(RESOURCE/img/inner-banner-schreiben-sie-uns.jpg);
		}

		&.service-buro {
			background-image: url(RESOURCE/img/inner-banner-service-buro.jpg);
		}

		&.buchungsanfrage {
			background-image: url(RESOURCE/img/inner-banner-buchungsanfrage.jpg);
		}

		&.kontakt {
			background-image: url(RESOURCE/img/inner-banner-kontakt.jpg);
		}*/

	}

	.reiseversicherung-logo {
		width: 100%;
	}
}