@import "mixin.css";


.layout-column {
	@mixin flexlayout column;
}

.layout-row {
	@mixin flexlayout row;
}

.flex {
	@mixin flex;
}

html {
	scroll-behavior: smooth;
}

#randomUnits {
	.unit-list-section {
		.single-unit {
			@media (max-width:992px) {
				height: auto;
			}
		}
	}
}

.size100 {
	@mixin size100;
}

* {
	outline: 0 !important;
}

.checkbox input[type=checkbox]:focus+label:before {
	outline: none !important;
	outline-offset: 0 !important;
}

.img-responsive {
	width: 100%;
}

*,
::before,
::after {
	--tw-ring-inset: var(--tw-empty, );
	--tw-ring-offset-width: 0px;
	--tw-ring-offset-color: #fff;
	--tw-ring-color: rgba(59, 130, 246, 0.5);
	--tw-ring-offset-shadow: 0 0 #0000;
	--tw-ring-shadow: 0 0 #0000;
	--tw-shadow: 0 0 #0000;
}

.inner-banner {
	position: relative;
}

.big-space {
	padding-top: 100px;
	padding-bottom: 80px;

	@media (max-width:992px) {
		padding-top: 60px;
	}
}

.unit-card-bl {
	position: relative;

	.container {
		position: relative;
		z-index: 1;
		top: -70px;
	}

	&:after {
		content: "";
		background-color: var(--color-grey-normal);
		width: 100%;
		height: 100%;
		position: absolute;
		bottom: 0px;
		z-index: 0;
	}
}

.alert-info {
	color: #4a586e;
	background-color: #E1E6EC;
	border-color: #E1E6EC;
}

p {
	a {
		color: #5e6a81;

		&:hover {
			text-decoration: underline;
		}
	}
}

.asd__month-name {
	font-size: 16px !important;
	line-height: 1.5em !important;
	font-weight: 600 !important;
	margin: 0 0 40px !important;
}

.asd__day-title {
	font-size: 12px !important;
	font-weight: 600 !important;
}

.container-small {
	max-width: 997px;
	margin: 0 auto;

	h1 {
		margin: 0 0 5px;
		font-size: 48px;
		font-weight: 400;
	}

	p {
		margin: 0 0 12px;
		font-size: 18px;
		line-height: 1.5;
	}
}

.page-title {
	max-width: 1199px;
	padding: 60px 0 40px;
}

.banner-arrow {
	width: 35px;
	height: 35px;
	left: 50%;
	bottom: 45px;
	position: absolute;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: hsla(0, 0%, 100%, .4);
	border: 1px solid #fff;
	border-radius: 50%;
	z-index: 99;
	transform: translate(-50%, 0);

	&:after {
		background-image: url(RESOURCE/img/arrow-right.svg);
		content: "";
		background-size: contain;
		background-repeat: no-repeat;
		display: inline-block;
		width: 8px;
		height: 13px;
		filter: brightness(4);
		transform: rotate(90deg);
	}
}

.unit-properties {
	display: flex;
	justify-content: start;
	align-items: center;
	flex-wrap: wrap;

	li {
		.icon {
			width: 23px;
			margin-right: 8px;

			&.icon-balcony {
				height: 16px;
			}

			&.icon-pets {
				height: 23px;
			}

			&.icon-sauna {
				height: 20px;
			}

			&.icon-parking {
				height: 27px;
			}

			&.icon-terrace {
				height: 25px;
			}

			&.icon-wifi {
				height: 19px;
			}

			&.icon-not-smoking {
				height: 23px;
			}

			&.icon-waschmaschine {
				height: 27px;
			}

			&.icon-no-hund {
				height: 23px;
			}
		}
	}
}

#toTop {
	width: 50px;
	height: 50px;
	bottom: 40px;
	right: 15px;
	position: fixed;
	display: none;
	text-align: center;
	background-color: #95A7BF;
	border-radius: 100%;
	font-size: 14px;
	line-height: 63px;
	text-decoration: none;
	color: #fff;
	z-index: 9999999;

	.icon-arrow {
		background-image: url(RESOURCE/img/arrow-right.svg);
		background-size: contain;
		background-repeat: no-repeat;
		display: inline-block;
		position: absolute;
		bottom: 25px;
		right: 19px;
		width: 12px;
		height: 15px;
		filter: brightness(4);
		transform: rotate(-90deg);
	}
}

.headtext {
	font-size: 22px;
	margin-bottom: 12px
}

.properties-block {
	display: flex;
	margin-bottom: 15px;
	padding-bottom: 15px;
	border-bottom: 1px solid #ddd;
	flex-wrap: wrap;

	.title {
		font-size: 16px;
		font-weight: bold;
		width: 30%;

		@media (max-width:992px) {
			width: 100%;
		}
	}

	.tick-li {
		width: 70%;
		display: flex;
		flex-wrap: wrap;
		margin-bottom: 20px;

		@media (max-width:992px) {
			font-size: var(--font-size-md);
			width: 100%;
		}

		li {
			width: 50%;
			padding-left: 20px;
			position: relative;
			font-size: 14px;


			&:before {
				font-size: 13px;
				content: "\f00c";
				font-family: "Font Awesome 5 Free";
				-webkit-font-smoothing: antialiased;
				font-weight: 600;
				display: inline-block;
				position: absolute;
				left: 0;
				top: 0;
				color: #42b12d;
			}
		}
	}
}

.widget-header-new {
	line-height: 1.5;
	padding-bottom: 4px;
	margin: 0;
	font-size: var(--h2-font-size);
	font-weight: var(--h2-font-weight);
	text-decoration: var(--h2-text-decoration);
	text-transform: var(--h2-text-transform);
	color: var(--widget-header-title-font-color);
}

.widget-block-new {
	border-bottom: 1px dotted #000;
	margin-bottom: 30px;
	padding: 0 0 30px;
}

.checkbox {
	label {
		padding-left: 10px !important;

		&:before {
			width: 20px !important;
			height: 20px !important;
			left: 0;
			top: 1px !important;
			position: absolute;
			display: block;
			border: 1px solid #bababf;
			border-radius: 2px;
			content: "";
			background-color: transparent !important;
		}
	}
}

.page-title {
	p {
		font-size: 22px;
	}
}

.side-contact {
	background-color: var(--color-grey);
	padding: 15px;
	position: sticky;
	top: 10px;
	border-bottom: 10px solid #D0D5DD;
	margin-bottom: 30px;
	width: 100%;


	/*&:after {
		content: "";
		position: absolute;
		bottom: 0;
		width: 100%;
		height: 10px;
		background-color: #d0d5dd;
	}*/

	.side-bl {
		display: flex;
		justify-content: space-between;
		align-items: center;
		font-size: 14px;

		.tel {
			color: #5e6a81;

			&:hover {
				text-decoration: underline;
			}
		}

		.side-img {
			width: 80px;
			height: 80px;
			display: inline-block;

			img {
				border-radius: 50%;
			}
		}
	}
}

a {
	pointer-events: unset !important;
}

html,
body {
	font-family: var(--font-family-main);
	color: var(--font-color-main);
	font-size: var(--font-size-main);
	font-weight: var(--font-weight-main);
}


h1,
.h1 {
	font-family: var(--h1-font-family);
	font-weight: var(--h1-font-weight);
	font-size: var(--h1-font-size);
	color: var(--h1-font-color);
	text-transform: var(--h1-text-transform);
	text-decoration: var(--h1-text-decoration);

	@media(max-width:992px) {
		font-size: 30px;
	}

}

.h1-width-small {
	line-height: var(--h1-font-size);

	small {
		font-size: var(--font-size-main);
		text-transform: none;
		display: inline-block;
	}
}

.coupon-field {
	.form-control {
		margin-right: 10px;
		border-radius: 5px !important;
	}
}

.form-bx-area,
.form-flex-area {
	.form-control {
		cursor: pointer;
	}

	.fa {
		pointer-events: none;
	}
}

strong {
	font-weight: 600;
}

h2,
.h2 {
	font-family: var(--h2-font-family);
	font-weight: var(--h2-font-weight);
	font-size: var(--h2-font-size);
	color: var(--h2-font-color);
	text-transform: var(--h2-text-transform);
	text-decoration: var(--h2-text-decoration);
	margin: 0;
	line-height: 1.5;
	padding-bottom: 4px;
}

h3,
.h3 {
	font-family: var(--h3-font-family);
	font-weight: var(--h3-font-weight);
	font-size: var(--h3-font-size);
	color: var(--h3-font-color);
	text-transform: var(--h3-text-transform);
	text-decoration: var(--h3-text-decoration);
}

h4,
.h4 {
	font-family: var(--h4-font-family);
	font-weight: var(--h4-font-weight);
	font-size: var(--h4-font-size);
	color: var(--h4-font-color);
	text-transform: var(--h4-text-transform);
	text-decoration: var(--h4-text-decoration);
}

h5,
.h5 {
	font-family: var(--h5-font-family);
	font-weight: var(--h5-font-weight);
	font-size: var(--h5-font-size);
	color: var(--h5-font-color);
	text-transform: var(--h5-text-transform);
	text-decoration: var(--h5-text-decoration);
}

.heading1 {
	display: inline-block;
	margin-bottom: var(--heading1-margin-bottom);
	text-transform: var(--heading1-text-transform);
	padding-bottom: 10px;
}

a {
	color: var(--link-font-color);
	text-decoration: var(--link-text-decoration);

	&:hover {
		text-decoration: var(--link-text-decoration-hover);
		color: var(--link-font-color-hover);
	}
}

.icon {
	width: 23px;
	height: 26px;
	display: inline-block;
	background-repeat: no-repeat;
	background-position: center;
	background-size: contain;

	&.icon-balcony {
		background-image: url(RESOURCE/img/icon-balkon.svg);
		width: 26px;
	}

	&.icon-pets {
		background-image: url(RESOURCE/img/icon-pets.svg);
		width: 20px;
	}

	&.icon-sauna {
		background-image: url(RESOURCE/img/icon-sauna.svg);
		width: 20px;

	}

	&.icon-parking {
		background-image: url(RESOURCE/img/icon-parken.svg);
		width: 18px;
	}

	&.icon-terrace {
		background-image: url(RESOURCE/img/icon-terrasse.svg);
		width: 25px;
	}

	&.icon-wifi {
		background-image: url(RESOURCE/img/icon-wlan.svg);
		width: 25px;
	}

	&.icon-not-smoking {
		background-image: url(RESOURCE/img/icon-not-smoking.svg);
		width: 25px;
	}

	&.icon-waschmaschine {
		background-image: url(RESOURCE/img/icon-waschmaschine.svg);
		width: 25px;
	}

	&.icon-pets-notallow {
		background-image: url(RESOURCE/img/icon-no-hund.svg);
		width: 25px;
	}

	&.icon-bad {
		background-image: url(RESOURCE/img/icon-bad.svg);
		width: 25px;
	}
}

.checkbox input[type=checkbox]:checked+label:after {
	content: "";
	background-image: url(RESOURCE/img/icon-check.svg);
	width: 14px;
	height: 16px;
	background-size: contain;
	color: transparent !important;
	left: 5px;
	top: 3px;
	background-repeat: no-repeat;
}

ul {
	padding: 0;
	margin: 0 0 15px;
	list-style: none;
}

.form-control:focus {
	box-shadow: none;
}

.btn:focus,
.btn:active {
	box-shadow: none;
}

/*--- background-colors---*/

.bg-primary {
	background-color: var(--color-primary);
}

.bg-primary-light {
	background-color: var(--color-primary-light);
}

.bg-secondary {
	background-color: var(--color-secondary);
}

.bg-secondary-light {
	background-color: var(--color-secondary-light);
}

.bg-white {
	background-color: var(--color-white);
}

.bg-grey-light {
	background-color: var(--color-grey-light);
}

.bg-grey-normal {
	background-color: var(--color-grey-normal);
}

.bg-grey-dark {
	background-color: var(--color-grey-dark);
}

.bg-black {
	background-color: var(--color-black);
}

/*--- font-colors---*/

.color-primary {
	color: var(--color-primary) !important;
}

.color-primary-light {
	color: var(--color-primary-light) !important;
}

.color-secondary {
	color: var(--color-secondary) !important;
}

.color-secondary-light {
	color: var(--color-secondary-light) !important;
}

.color-white {
	color: var(--color-white) !important;
}

.color-grey-light {
	color: var(--color-grey-light) !important;
}

.color-grey-normal {
	color: var(--color-grey-normal) !important;
}

.color-grey-dark {
	color: var(--color-grey-dark) !important;
}



.app-loaded #preloader {
	display: none;
}

.app-leaving #preloader {
	display: block;
	opacity: 0.6;
}

/*--- Datepicker ---*/

.datepicker-trigger {

	.asd__day--disabled.asd__day--in-range {
		background: var(--cal-selection-range) !important;
		opacity: 1 !important;
		border: 1px double var(--cal-selected-border) !important;
	}

	.asd__day--enabled.asd__day--in-range {
		background: var(--cal-selection-range) !important;
		color: var(--cal-text-color-selected) !important;
		opacity: 1 !important;
		border: 1px double var(--cal-selected-border) !important;
	}


	.asd__day--disabled.asd__day--hovered {
		background: var(--cal-selection-range) !important;
		opacity: 1 !important;
		border: 1px double var(--cal-selected-border) !important;
	}

	.asd__day--enabled.asd__day--hovered {
		background: var(--cal-selection-range) !important;
		opacity: 1 !important;
		border: 1px double var(--cal-selected-border) !important;
	}

	.asd__day--enabled {
		background-color: var(--cal-available) !important;
		color: var(--cal-text-color) !important;

		&.asd__day--disabled {
			background-color: var(--cal-disabled) !important;
			opacity: 0.8 !important;
			text-decoration: underline;

			&.asd__day--selected {
				background-color: var(--cal-selected) !important;
			}

			&.asd__day--in-range {
				background: var(--cal-selection-range) !important;
				opacity: 1 !important;
				border: 1px double var(--cal-selected-border) !important;
			}

			/*	&.asd__day--hovered {
				background: var(--cal-selection-range) !important;
				opacity: 1 !important;
				border: 1px double var(--cal-selected-border) !important;
			} */
		}


	}

	.asd__day--enabled:not(.asd__day--disabled) {
		&:hover {
			background: var(--cal-selected) !important;
			opacity: 1 !important;
			color: var(--cal-text-color-hover) !important;
		}
	}

	.asd__day--selected {
		background: var(--cal-selected) !important;
		color: var(--cal-text-color-selected) !important;
		opacity: 1 !important;
		border: 1px double var(--cal-selected-border) !important;

		&.asd__selected-date-one {
			position: relative;

			&:before {
				content: "";
				position: absolute;
				border-left: 12px solid transparent;
				border-top: 19px solid var(--cal-selection-range);
				border-bottom: 20px solid var(--cal-selection-range);
				right: 0px;
			}
		}

		&.asd__selected-date-two {
			position: relative;

			&:before {
				content: "";
				position: absolute;
				border-right: 12px solid transparent;
				border-top: 19px solid var(--cal-selection-range);
				border-bottom: 20px solid var(--cal-selection-range);
				left: 0px;
			}
		}

	}
}

/*--- Buttons ---*/


.btn {
	border-radius: var(--btn-border-radius);
	font-size: var(--btn-font-size);
	/*	padding: 8px 16px; */
	text-transform: var(--btn-text-transform);



	&.btn-primary,
	&.btn-go {
		background-color: var(--btn-primary-bg-color);
		color: var(--btn-primary-font-color);
		border-color: var(--btn-primary-border-color);

		&:hover {
			background-color: var(--btn-primary-bg-color-hover);
			border-color: var(--btn-primary-border-color-hover);
			color: var(--btn-primary-font-color-hover);
		}

		&.btn-lg {
			padding-left: 50px;
			padding-right: 50px;
		}
	}

	&.btn-secondary {
		background-color: var(--btn-secondary-bg-color);
		color: var(--btn-secondary-font-color);
		border-color: var(--btn-secondary-border-color);

		&:hover {
			background-color: var(--btn-secondary-bg-color-hover);
			border-color: var(--btn-secondary-border-color-hover);
			color: var(--btn-secondary-font-color-hover);
		}
	}

	&.btn-default {
		background-color: var(--btn-default-bg-color);
		color: var(--btn-default-font-color);
		border-color: var(--btn-default-border-color);

		&:hover {
			background-color: var(--btn-default-bg-color-hover);
			border-color: var(--btn-default-border-color-hover);
			color: var(--btn-default-font-color-hover);
		}
	}

	&.btn-add {
		background-color: var(--btn-add-bg-color);
		color: var(--btn-add-font-color);
		border-color: var(--btn-add-border-color);

		&:hover {
			background-color: var(--btn-add-bg-color-hover);
			border-color: var(--btn-add-border-color-hover);
			color: var(--btn-add-font-color-hover);
		}
	}

	&.btn-remove {
		background-color: var(--btn-remove-bg-color);
		color: var(--btn-remove-font-color);
		border-color: var(--btn-remove-border-color);

		&:hover {
			background-color: var(--btn-remove-bg-color-hover);
			border-color: var(--btn-remove-border-color-hover);
			color: var(--btn-remove-font-color-hover);
		}
	}

	&.btn-bc {
		background-color: var(--btn-breadcrumb-bg-color);
		color: var(--btn-breadcrumb-font-color);
		border-color: var(--btn-breadcrumb-border-color);

		&:hover {
			background-color: var(--btn-breadcrumb-bg-color-hover);
			border-color: var(--btn-breadcrumb-border-color-hover);
			color: var(--btn-breadcrumb-font-color-hover);
		}
	}

	&.btn-sm {
		font-size: var(--font-size-sm);
		padding: 6px 12px;
	}

	&.btn-lg {
		height: 46px;
		font-size: var(--font-size-lg);
		padding: 10px 16px;
	}

	&.big-pad {
		padding: 8px 30px;
	}

	&:focus {
		outline: 0 !important;
		text-decoration: none !important;
	}

	&.btn-arrow {
		min-width: 220px;
		display: inline-flex;
		align-items: center;
		justify-content: space-between;

		@media (max-width:992px) {
			min-width: 160px;
		}

		.fa {
			opacity: .4;
			margin-left: 15px;
		}
	}
}

.breadcrumb {
	background-color: #fff !important;
	padding-left: 0px;
	padding-bottom: 0;

}

.btn-breadcrumb .btn:not(:last-child):after {
	content: " ";
	display: block;
	width: 0;
	height: 0;
	border-top: 17px solid transparent;
	border-bottom: 17px solid transparent;
	border-left: 10px solid white;
	position: absolute;
	top: 50%;
	margin-top: -17px;
	left: 100%;
	z-index: 3;
}

.btn-breadcrumb .btn:not(:last-child):before {
	content: " ";
	display: block;
	width: 0;
	height: 0;
	border-top: 17px solid transparent;
	border-bottom: 17px solid transparent;
	border-left: 10px solid rgb(173, 173, 173);
	position: absolute;
	top: 50%;
	margin-top: -17px;
	margin-left: 1px;
	left: 100%;
	z-index: 3;
}

.btn-breadcrumb .btn {
	padding: 6px 12px 6px 24px;

	&.disabled {
		opacity: 0.6;
		color: #000;

	}
}

.btn-breadcrumb .btn:first-child {
	padding: 6px 6px 6px 10px;
}

.btn-breadcrumb .btn:last-child {
	padding: 6px 18px 6px 24px;
}

.btn-breadcrumb .btn.btn-bc:not(:last-child):after {
	border-left: 10px solid var(--btn-breadcrumb-bg-color);
}

.btn-breadcrumb .btn.btn-bc:not(:last-child):before {
	border-left: 10px solid var(--btn-breadcrumb-bg-color);
}

.btn-breadcrumb .btn.btn-bc:hover:not(:last-child):after {
	border-left: 10px solid var(--btn-breadcrumb-bg-color-hover);
}

.btn-breadcrumb .btn.btn-bc:hover:not(:last-child):before {
	border-left: 10px solid var(--btn-breadcrumb-bg-color-hover);
}



.breadcrumb li a:hover:after {
	border-left-color: #ffc107 !important;
}

.next-prev-btns {
	position: relative;

	.owl-nav {
		position: absolute;
		top: 40%;
		left: 0;
		width: 100%;

		@media (max-width:576px) {
			top: 20%;
		}




		button {
			width: 40px;
			height: 40px;
			border-radius: 50%;
			border-radius: 50%;
			box-shadow: 0 0 8px rgba(0, 0, 0, .21);
			background-color: var(--color-primary) !important;
			position: absolute;
			left: 0;

			@media (max-width:991px) and (min-width:600px) {
				left: -30px;
			}

			@media (min-width:1200px) {
				left: -50px;
			}


			span {
				font-family: "Font Awesome 5 Free";
				font-weight: 900;
				-webkit-font-smoothing: antialiased;
				display: inline-block;
				font-style: normal;
				font-variant: normal;
				text-rendering: auto;
				line-height: 1;
				color: transparent;

				&:before {
					color: var(--color-white);
					font-family: "Font Awesome 5 Free";
					position: relative;
					left: 2px;
					font-size: 16px;
					top: 1px;
				}
			}

			&.owl-next {
				position: absolute;
				right: 0;
				left: initial;

				@media (max-width:991px) and (min-width:600px) {
					right: -30px;
				}

				@media (min-width:1200px) {
					right: -50px;
				}

				/*	@media (max-width:992px) {
					right: -6px;
				} */

				span {

					&:before {
						content: "\f054";
						left: 3px;
					}
				}
			}

			&.owl-prev {
				span {

					&:before {
						content: "\f053";

					}
				}
			}
		}
	}
}

.flex-align-center {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.unit-list-section {
	.col-md-3 {
		margin-bottom: 10px;
	}
}

.single-unit {
	margin: 0 auto 10px;
	max-width: 500px;
	transition: all 0.25s ease 0s;
	box-shadow: 0px 1px 11px 1px rgba(133, 147, 179, 0.3);
	height: 100%;
	background-color: #fff;

	&:hover {
		box-shadow: 0 2px 11px 1px rgba(0, 0, 0, .2);
	}
}

.owl-carousel.off {
	display: block;
}

.payment-view {
	margin-top: var(--page-margin-top);
	padding-top: 30px;
	padding-bottom: 60px;
}

.list-li {
	list-style: disc;
	padding-left: 15px;
}



.vertical-center {
	margin: 0;
	position: absolute;
	top: 50%;
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
}

.googleMapsHint {
	color: var(--googlemaps-hint-text-color);
	font-size: var(--googlemaps-hint-font-size);
	width: 100%;
}

.no-google-map {
	width: 100%;
	height: 150px;
	position: relative;
}

.box {
	position: absolute;
	z-index: 999 !important;
}

.datepicker-ext-row {
	display: flex;
	align-items: center;
	margin: 20px 0 0 0 !important;

	@media (max-width: 767px) {
		flex-wrap: wrap;
		max-width: 280px;
		justify-content: center;
		width: 100%;
		margin: 30px auto 0 !important;
	}

	span {
		font-size: 15px;

		@media (max-width: 767px) {
			margin: 0 0 15px 0;
			max-width: 90px;
			display: inline-block;
			width: 100%;
		}
	}

	select {
		height: 40px;
		max-width: 130px;
		width: 100%;
		border-radius: 0;
		padding-left: 5px;
		padding-right: 5px;
		margin: 0 20px 0 10px;
		border: 1px solid rgba(0, 0, 0, .2);
		background-position: 96% 16px;

		@media (max-width: 767px) {
			margin: 0 20px 10px 10px;
			max-width: 160px;
		}

		@media all and (min-width: 400px) and (max-width: 480px) {
			max-width: 180px;
		}
	}
}

/*@media (min-width:1200px) {
	.container {
		max-width: 1230px;
	}
}*/

.wrap-box {
	max-width: 1430px;
	margin: 0 auto;
	padding: 0 15px;

	.container {
		max-width: 1230px;
	}

	.container-small {
		&.py-5 {
			@media (max-width:767px) {
				padding: 0 !important;
			}
		}
	}
}

.pswp__caption__center {
	text-align: center !important;
}

.hide-mob {
	display: block;

	@media (max-width:992px) {
		display: none;
	}
}

.show-mob {
	display: none;

	@media (max-width:992px) {
		display: block;
	}
}

.fal {
	font-family: 'Font Awesome 5 Pro' !important;
	font-weight: 300;
}

.far {
	font-family: 'Font Awesome 5 Pro' !important;
	font-weight: 400;

}

.covid-section {

	margin: 0;
	position: absolute;
	bottom: 40px;
	right: 40px;
	z-index: 2;


	@media (max-width: 1140px) {
		right: 20px;
		top: 120px;
		bottom: inherit;
	}

	@media (max-width: 768px) {
		right: 20px;
		top: 129px;
		bottom: inherit;
	}

	@media (max-width: 560px) {
		right: 10px;
		top: 129px;
		bottom: inherit;
	}


	.btn {
		width: 160px;
		height: 160px;
		border-radius: 100px;

		@media (max-width:1170px) {
			width: 130px;
			height: 130px;
			white-space: unset;
		}

		@media (max-width: 992px) {
			width: 110px;
			height: 110px;
			font-size: 14px;

		}

		@media (max-width: 768px) {
			width: 98px;
			height: 98px;
			font-size: 13px;

		}
	}


}


.tooltip {
	.tooltip-inner {
		background-color: var(--color-white) !important;
		color: var(--color-black) !important;
		border: 1px solid var(--color-grey-normal);
	}
}

.bs-tooltip-top .arrow:before {
	border-top-color: var(--color-grey-normal) !important;
}

.modal-dialog {
	max-width: 800px;
	margin: 30px auto;
	width: 100%;
}

.openstreetwarning {
	position: relative;
	background-color: #000;
	aspect-ratio: 16/9;
	padding: 20px;
	color: #fff;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-wrap: wrap;
	flex-direction: column;

	@media(max-width:1366px) {
		aspect-ratio: auto;
		min-height: 350px;
	}

	@media(max-width:500px) {
		/*padding: 5px;*/
		font-size: 13px;
	}


	.text {
		text-align: center;
	}


	.button-line {
		text-align: center;
		margin: 10px 0px;


		/*@media(max-width:500px) {
			margin: 0px 0px;
		}*/
	}


	a {
		color: var(--color-white);
	}

}

@font-face {
	font-family: 'Font Awesome 5 Pro';
	font-style: normal;
	font-weight: 300;
	font-display: block;
	src: url("RESOURCE/fonts/fa-light-300.eot");
	src: url("RESOURCE/fonts/fa-light-300.eot?#iefix") format("embedded-opentype"),
		url("RESOURCE/fonts/fa-light-300.woff2") format("woff2"),
		url("RESOURCE/fonts/fa-light-300.woff") format("woff"),
		url("RESOURCE/fonts/fa-light-300.ttf") format("truetype"),
		url("RESOURCE/fonts/fa-light-300.svg#fontawesome") format("svg");
}

@font-face {
	font-family: 'Font Awesome 5 Pro';
	font-style: normal;
	font-weight: 400;
	font-display: block;
	src: url("RESOURCE/fonts/fa-regular-400.eot");
	src: url("RESOURCE/fonts/fa-regular-400.eot?#iefix") format("embedded-opentype"),
		url("RESOURCE/fonts/fa-regular-400.woff2") format("woff2"),
		url("RESOURCE/fonts/fa-regular-400.woff") format("woff"),
		url("RESOURCE/fonts/fa-regular-400.ttf") format("truetype"),
		url("RESOURCE/fonts/fa-regular-400.svg#fontawesome") format("svg");
}

@font-face {
	font-family: 'Font Awesome 5 Pro';
	font-style: normal;
	font-weight: 300;
	font-display: block;
	src: url("RESOURCE/fonts/font_awesome_5_pro-light.eot");
	src: url("RESOURCE/fonts/font_awesome_5_pro-light.eot?#iefix") format("embedded-opentype"),
		url("RESOURCE/fonts/font_awesome_5_pro-light.woff2") format("woff2"),
		url("RESOURCE/fonts/font_awesome_5_pro-light.woff") format("woff"),
		url("RESOURCE/fonts/font_awesome_5_pro-light.ttf") format("truetype"),
		url("RESOURCE/fonts/font_awesome_5_pro-light.svg#fontawesome") format("svg");
}