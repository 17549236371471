.faq-v2 {
	margin-top: var(--page-margin-top);

	.card {
		margin-top: 15px;
		padding-top: 25px;
		border: none;
		border-top: 1px solid #ccc;
		box-shadow: inherit;
		border-radius: 0;

		.card-header {
			background-color: transparent;
			border: none;
			padding: 0px 5px;
			margin: 0 0 12px;
		}

		.card-body {
			padding: 0 36px;
		}

	}


	.btn-link {
		font-weight: 600;
		color: var(--faq-title-font-color);
		text-decoration: none;
		width: 100%;
		text-align: left;
		padding: 0;
		font-family: var(--font-family-main);
		font-size: 15px;
		padding-left: 25px;
		position: relative;

		&:hover {
			color: #95A7BF !important;
		}

		&:before {
			background-image: url(RESOURCE/img/icon-plus.svg);
			width: 16px;
			height: 22px;
			display: inline-block;
			content: "\f067";
			padding-right: 5px;
			margin-right: 10px;
			background-size: contain;
			background-position: center;
			background-repeat: no-repeat;
			position: absolute;
			left: 0;
		}

		&[aria-expanded="true"] {
			&:before {
				background-image: url(RESOURCE/img/icon-minus.svg);
			}
		}

		&:hover {
			color: var(--faq-title-font-color);
			text-decoration: none;
		}
	}


}