.unit-page-layout-v1 {

	/*margin-top: var(--page-margin-top);*/
	@media (max-width:992px) {
		margin-top: 100px;
	}

	.asd__wrapper {
		border: none !important;

		@media (min-width:1201px) {
			max-width: 1200px !important;
		}

		@media (max-width:1200px) and (min-width:993px) {
			max-width: 1110px !important;
		}

		@media (min-width:993px) {
			width: 100% !important;
		}
	}

	.asd__inner-wrapper {
		@media (min-width:1201px) {
			margin-left: -420px !important;
		}

		@media (max-width:1200px) and (min-width:993px) {
			margin-left: -385px !important;
		}
	}

	.asd__days-legend {


		@media (min-width:1201px) {
			width: 418px !important;
		}

		@media (max-width:1200px) and (min-width:993px) {
			width: 386px !important;
		}

		@media (min-width:993px) {
			padding-left: 0px !important;
			padding-right: 45px !important;
		}
	}

	.asd__datepicker-header div:nth-child(4n) {
		@media (min-width:1201px) {
			left: 415px !important;
		}

		@media (max-width:1200px) and (min-width:993px) {
			left: 386px !important;
		}
	}

	.asd__datepicker-header div:nth-child(5n) {
		@media (min-width:1201px) {
			left: 830px !important;
		}

		@media (max-width:1200px) and (min-width:993px) {
			left: 770px !important;
		}
	}

	.asd__inner-wrapper .asd__month {


		@media (min-width:1201px) {
			width: 418px !important;
		}

		@media (max-width:1200px) and (min-width:993px) {
			width: 386px !important;
		}

		@media (min-width:993px) {
			padding-right: 50px !important;
			padding-left: 0 !important;
		}
	}

	.asd__change-month-button--previous {
		@media (min-width:993px) {
			padding-left: 0 !important;
		}
	}

	.asd__change-month-button--next {
		@media (min-width:993px) {
			padding-right: 0 !important;
		}
	}



	.unit-book-now-v3 {
		background-color: #f6f6f6;
		padding: 20px 15px 0;


		.reqired {
			color: red;
			margin-left: 3px;
		}

		.checkbox {
			label {
				margin: 0 0 5px 0;
			}

			.privacy-link {
				text-decoration: underline;

				&:hover {
					text-decoration: none;
				}
			}
		}

	}

	.sticky-side-booking {
		position: -webkit-sticky;
		position: sticky;
		z-index: 12;
		top: 60px;



		.side-contact {
			position: static;
		}
	}

	.unit-properties {
		li {
			width: 33.33%;
			margin-bottom: 15px;
			display: flex;
			align-items: center;

			@media (max-width:992px) {
				width: 50%;
			}

			@media (max-width:450px) {
				width: 100%;
			}
		}
	}



	.heading2 {
		font-size: 40px;
		color: #0D0D0D;

		@media(max-width:992px) {
			font-size: 26px;
		}
	}

	.sidebar-map {
		height: 376px;
	}

	.properties-bl {
		display: flex;
		flex-wrap: wrap;
		align-items: baseline;

		.properties {

			margin-right: 12px;
			padding: 0 13px;
			background-color: #F4F4F4;
			font-size: 14px;
			line-height: 37px;
		}
	}

	.header-block {
		display: flex;
		justify-content: space-between;
		align-items: center;
		flex-wrap: wrap;
		border-bottom: 1px dotted #000;
		margin-bottom: 40px;
		padding: 20px 0;
		color: #4a586e;

		.rating {
			display: flex;
			line-height: 25px;
			font-weight: bold;
			color: #8D9FB9;
			justify-content: end;
			align-items: center;


			@media(min-width:993px) {
				justify-content: start;
			}

			.fa-star {
				font-size: 13px;
			}

			.voteAverage {
				color: #4a586e;
				padding: 0 6px;
			}
		}

	}



}

.unit-section {
	.title {
		margin-bottom: 8px;
		margin-top: 8px;
		font-size: 24px;
		line-height: 1;
		font-weight: 400;
		text-transform: uppercase;
		font-family: BebasNeue, sans-serif;
	}

	.map-sub-title {
		margin-bottom: 8px;
		margin-top: 8px;
		font-size: 20px;
		line-height: 1;
		text-transform: uppercase;
		font-family: BebasNeue, sans-serif;
	}
}

.propeties {
	display: flex;
	flex-wrap: wrap;

	.distance-value {
		float: right;
	}

	div {
		margin-right: 2%;
		width: 49%;
		padding: 5px 15px;

		@media(max-width:767px) {
			width: 100%;
		}

		&:nth-child(2n) {
			margin-right: 0;
		}

		&:nth-child(1),
		&:nth-child(2),
		&:nth-child(5),
		&:nth-child(6),
		&:nth-child(9),
		&:nth-child(10) {
			@media(min-width:768px) {
				background-color: #F4F7FA;
			}

		}

		&:nth-child(odd) {
			@media(max-width:767px) {
				background-color: #F4F7FA;
			}

		}

	}
}