.searchbar-v1 {
	position: absolute;
	bottom: -30px;
	width: 100%;

	.search-home-bl {
		background-color: var(--color-grey-normal);
		border-bottom: 30px solid var(--color-grey-normal);
		padding: 25px;
		max-width: 390px;

		h2 {
			margin-bottom: 15px;
			font-size: 33px;
			color: #4A586E;
		}
	}

	@media (max-width: 576px) {
		padding: 0px 10px;
	}

	.title {
		color: var(--color-primary);

	}

	.search-form-area {
		.form-btn {
			.searchbox {
				margin-bottom: 20px;
				padding-bottom: 20px;
				border-bottom: 1px dotted #0d0d0d;
			}
		}

		.form-flex {

			h3 {
				margin-bottom: 6px;
				font-size: 18px;
				color: #4A586E;
			}

			@media (max-width:992px) {
				width: 100%;

				&.v2 {
					width: 100%;
				}
			}

			.fa {
				position: absolute;
				right: 8px;
				top: 10px;

				&.fa-calendar {
					&:before {
						background-image: url(RESOURCE/img/icon-kalender.svg);
						content: "";
						background-size: contain;
						background-repeat: no-repeat;
						display: inline-block;
						width: 20px;
						height: 20px;
					}

				}

				&.fa-user {
					right: 15px;

					&:before {
						background-image: url(RESOURCE/img/arrow-right.svg);
						content: "";
						background-size: contain;
						background-repeat: no-repeat;
						display: inline-block;
						width: 7px;
						height: 7px;
						filter: brightness(0) saturate(100%);
						transform: rotate(90deg);
					}
				}
			}

			.form-control {
				padding: 0 10px;
				background-color: #fff;
				background-color: var(--white);
				text-align: left;
				height: 45px;
				border-radius: 3px;
				font: 14px/45px 'Open Sans', Arial, sans-serif;

			}

			>label {

				color: var(--black);
				font-size: 13px;
				text-transform: uppercase;
				display: none;
				font-weight: normal;
				letter-spacing: 1.5px;

				@media (max-width:992px) {
					display: none;
				}


			}

			.form-flex-area {
				position: relative;
				margin-bottom: 10px;

				.search-link {
					position: absolute;
					right: 0px;
					top: 9px;
					width: 23px;
					height: 23px;

					li {
						&.fa {
							position: relative;
							top: 4px;
							right: 0;

							&.fa-search {
								&:before {
									background-image: url(RESOURCE/img/icon-search.svg);
									content: "";
									background-size: contain;
									background-repeat: no-repeat;
									display: inline-block;
									width: 23px;
									height: 20px;
									filter: brightness(0) saturate(100%);
								}
							}
						}
					}
				}
			}
		}

		.datepicker-trigger {
			.asd__wrapper {
				@media (max-width: 767px) {
					margin: 0 auto;
				}
			}
		}


	}

	.form-control:disabled,
	.form-control[readonly] {
		background-color: var(--color-white);
	}

	.list-group-item {
		color: var(--font-color-main);
		text-decoration: none;

		&:hover {
			color: var(--font-color-main);
			text-decoration: none;
		}

		&.active {
			background-color: transparent;
			border: 1px solid var(--color-primary);
		}
	}
}