.unit-feedbacks-v1 {
	.widget-block {
		padding-bottom: 0;
	}

	.fa-star {
		color: var(--color-secondary)
	}

	.ptitle {
		display: inline-block;
		margin-left: 5px;
	}

	.ratings {
		display: flex;
		flex-wrap: wrap;

		.rating {
			margin: 0 10px 15px 0;

			.fa {
				color: var(--color-secondary)
			}

			&:nth-last-child(-n+1) {
				margin: 0;
			}
		}


	}

	.feedbacks {

		clear: left;
		padding-top: 15px;


		.showLess {
			position: relative;

			.feedback {
				display: none;

				&:nth-child(-n+2) {
					display: block;
				}
			}

			&.showFull {
				.feedback {
					display: block;
				}

				.widget {

					.widget-block {
						height: auto;
						overflow: visible;
					}
				}


				.hide-show-btn {

					&:after {
						content: "\f106";
					}
				}
			}

			.hide-show-btn {
				position: absolute;
				bottom: -45px;
				left: 50%;
				transform: translate(-50%, 0);
				padding: 5px 15px;
				background-color: var(--white);
				display: inline-block;
				border: 1px solid var(--btn-border-color-hide-show);
				font-size: var(--font-size-md);

				&:focus,
				&:hover {
					text-decoration: none;
				}

				&:after {
					content: "\f107";
					margin-left: 10px;
					margin-left: 5px;
					font-family: fontawesome;
				}
			}
		}



		.feedback {
			/*border-left: 3px solid var(--color-grey-normal);
			background-color: var(--color-grey-light);
			padding: 10px;
			font-style: italic;*/
			margin-bottom: 30px;

			.fa-star {
				&:before {
					content: "";
					background: url(RESOURCE/img/icon_star.svg) no-repeat 0 0;
					position: absolute;
					height: 15px;
					width: 15px;
					background-size: cover;
					top: 0;
					left: 0;
				}
			}

			.rating {
				font-size: var(--font-size-sm);
			}

			.guest-text {
				clear: left;
				padding-top: 0;
				font-size: 14px;

				@media (min-width:768px) {
					width: 80%;
				}
			}

			.author {
				font-size: 13px;
				color: var(--color-secondary);
			}

			.comment {
				padding-left: 10px;
				border-left: 3px dotted var(--color-red);
				font-size: var(--font-size-md);
			}

			.agency {
				padding-bottom: 10px;
			}
		}
	}

	.feedback-header {
		display: flex;
		justify-content: space-between;

		@media (max-width:767px) {
			flex-direction: column;
		}

		.header-rating {
			font-size: 14px;
			display: flex;
			align-items: center;
			color: #4A586E;

			.rating-text {
				color: var(--color-secondary)
			}
		}
	}

	.feedback-comment-header {
		display: flex;
		justify-content: space-between;

		@media (max-width:767px) {
			flex-direction: column;
			align-items: self-start;
			margin-bottom: 20px;
		}

		.title {
			font-size: 14px;
			line-height: 1.3;
			font-family: var(--font-family-main);

			@media (min-width:768px) {
				width: 80%;
			}
		}


		.rating {
			margin-bottom: 0;

			i {


				&.fa-smile {
					&:before {
						content: "";
						height: 18px;
						width: 18px;

					}
				}

				&.fa-couch {
					width: 18px;

					&:before {
						content: "";
						height: 15px;
						width: 21px;

					}
				}

				&.fa-map-marker-alt {
					width: 10px;

					&:before {
						content: "";
						height: 16px;
						width: 12px;
					}
				}

				&.fa-credit-card {
					font-size: 16px;

					&:before {

						position: absolute;
						top: 3px;
					}
				}

				&.fa-thumbs-up {
					font-size: 18px;

					&:before {

						position: absolute;
						top: 3px;
					}
				}
			}
		}

		i {
			width: 15px;
			position: relative;
			height: 15px;
			margin-right: 7px;

			&.fa-smile {
				&:before {
					content: "";
					background: url(RESOURCE/img/icon-smile1.svg) no-repeat 0 0;
					position: absolute;
					height: 15px;
					width: 15px;
					background-size: cover;
					top: 3px;
					left: 0;
				}
			}

			&.fa-couch {
				width: 20px;

				&:before {
					content: "";
					background: url(RESOURCE/img/icon-couch1.svg) no-repeat 0 0;
					position: absolute;
					height: 15px;
					width: 20px;
					background-size: cover;
					top: 3px;
					left: 0;
				}
			}

			&.fa-map-marker-alt {
				&:before {
					content: "";
					background: url(RESOURCE/img/icon-location-dot1.svg) no-repeat 0 0;
					position: absolute;
					height: 15px;
					width: 11px;
					background-size: cover;
					top: 3px;
					left: 0;
				}
			}
		}
	}

	.cta-feedback {
		padding: 10px 15px;
		border: 1px solid #000;
		border-radius: 5px;
		font-size: 13px;
		border-radius: 3px;
		font-weight: bold;


	}

	i {
		width: 15px;
		position: relative;
		height: 15px;
		margin-right: 5px;

		&.fa-smile {
			&:before {
				content: "";
				background: url(RESOURCE/img/icon-smile.svg) no-repeat 0 0;
				position: absolute;
				height: 15px;
				width: 15px;
				background-size: cover;
				top: 3px;
				left: 0;
			}
		}

		&.fa-couch {
			width: 20px;

			&:before {
				content: "";
				background: url(RESOURCE/img/icon-couch.svg) no-repeat 0 0;
				position: absolute;
				height: 15px;
				width: 20px;
				background-size: cover;
				top: 3px;
				left: 0;
			}
		}

		&.fa-map-marker-alt {
			&:before {
				content: "";
				background: url(RESOURCE/img/icon-location-dot.svg) no-repeat 0 0;
				position: absolute;
				height: 15px;
				width: 11px;
				background-size: cover;
				top: 3px;
				left: 0;
			}
		}
	}

	.tooltip-bl {
		cursor: help;
		margin-top: 2px;
		font-size: 12px;
		color: #8D9FB9;
		display: flex;
		font-weight: 700;

	}

	.fa-calendar {
		&:before {
			content: "";
			background: url(RESOURCE/img/calendar-days-light.svg) no-repeat 0 0;
			position: absolute;
			height: 15px;
			width: 11px;
			background-size: cover;
			top: 3px;
			left: 0;
		}
	}

	.feedback-caption {
		display: flex;
		font-size: 13px;
		color: var(--color-secondary);
		margin-left: 10px;
		font-weight: 700;

		.authot-bl {
			margin-left: 15px;

			.user-icon {
				width: 11px;
				display: inline-block;

				&:before {
					content: "";
					background: url(RESOURCE/img/iconpeople.svg) no-repeat 0 0;
					position: absolute;
					height: 13px;
					width: 11px;
					background-size: cover;
					top: 3px;
					left: 0;
				}
			}
		}
	}

	.feedback-rating-header {
		display: flex;
		align-items: flex-start;
	}
}