.mobil-menu {
	display: none;
}

.header-fastnav-search {

	position: absolute;
	top: -100px;
	background-color: #f4f7fa;
	z-index: 10;
	right: 0px;
	max-width: 280px;
	padding: 8px 15px;
	visibility: hidden;
	opacity: 0;



	&.show {
		top: var(--page-margin-top);
		visibility: visible;
		opacity: 1;

		@media (max-width:992px) {
			top: 0;
		}
	}


	.close {
		text-align: right;
		margin-top: -10px;
		cursor: pointer;
		margin-bottom: -3px;
	}

	.input-group {
		width: 250px;


		.form-control {
			padding: 5px 10px;
			width: 200px;

		}

		.form-control::placeholder {
			font-size: 0.875rem;
			color: #333;
			font-style: italic;
		}

		.btn {
			border-radius: 0 5px 5px 0;
		}
	}
}

.navbar-v1.affix,
.navbar-v1 {
	position: relative;
	width: 100%;
	top: 0;
	border-width: 0 0 1px;
	padding: 0;
	z-index: 9;
	background-color: var(--color-white);

	@media (max-width:992px) {
		position: absolute;
	}



	.header-block {
		display: flex;
		align-items: center;
		justify-content: space-between;
		max-width: 1200px;
		margin: 0 auto;
		height: 160px;
		position: relative;

		@media (max-width:992px) {
			height: 90px;
		}

		.logo {
			@media (max-width:992px) {
				width: 110px;
			}
		}

		.main-menu {
			padding: 40px 30px;
			background-color: #fff;
			box-shadow: 0 5px 10px 0 rgb(0 0 0 / 15%);
			width: 100%;
			position: absolute;
			top: 100%;
			width: 100%;
			left: 0;
			z-index: 49;

			@media (max-width:992px) {
				padding: 15px 20px;
			}

			.dropdown {
				.dropdown-toggle {
					line-height: 42px;
					font-size: 25px;
					font-weight: normal;
					color: var(--color-primary);
					font-family: var(--title-font-family-main);

					@media (max-width:992px) {
						font-size: 18px;
					}


					&:after {
						@media (min-width:993px) {
							display: none;
						}
					}
				}


			}

			.dropdown-menu {
				position: static !important;
				width: 100%;
				min-width: inherit;
				border: none;
				margin: 0;
				padding: 0;
				flex-wrap: wrap;
				transform: inherit !important;

				@media (min-width:1201px) {
					display: block !important;
				}

				li {
					a {
						display: block;
						border-top: 1px solid #ddd;
						line-height: 36px;
						font-size: 16px;
					}
				}
			}

			>ul {
				display: flex;
				justify-content: center;
				flex-wrap: wrap;

				>li {
					width: 33.33%;
					margin-bottom: 0;
					padding-right: 30px;
					padding-left: 30px;

					@media (max-width:992px) {
						width: 100%;
						padding: 0;

					}
				}
			}
		}

		.navbar-bl {
			ul {
				display: flex;
				align-items: center;
				margin-bottom: 0;
				border-right: 1px solid #ddd;

				li {
					a {
						width: 120px;
						height: 56px;
						text-align: center;
						border-left: 1px solid #ddd;
						font-size: 13px;
						line-height: 1;
						color: var(--color-primary);
						cursor: pointer;
						display: block;

						@media (max-width:992px) {
							width: 45px;
							height: auto;

						}

						@media (max-width:575px) {
							width: 35px;

						}

						i {
							&.icon {
								background-repeat: no-repeat;
								background-size: cover;
								width: 30px;
								height: 30px;
								display: inline-block;
								margin-bottom: 5px;
								background-position: center;

								@media (max-width:575px) {
									width: 20px;
									height: 20px;
								}
							}

							&.fa-search {
								background-image: url(RESOURCE/img/icon-search.svg);

								&:before {
									content: "";
								}
							}

							&.fa-heart {
								background-image: url(RESOURCE/img/icon-heart.svg);

								&:before {
									content: "";
								}
							}

							&.fa-phone-alt {
								background-image: url(RESOURCE/img/icon-phone.svg);

								&:before {
									content: "";
								}
							}

							&.fa-bars {
								background-image: url(RESOURCE/img/icon-bars.svg);

								&:before {
									content: "";
								}
							}

							&.icon-house {
								background-image: url(RESOURCE/img/icon-house.svg);
								filter: brightness(0) saturate(100%) invert(31%) sepia(40%) saturate(312%) hue-rotate(178deg) brightness(96%) contrast(89%);
							}


							/*font-size: 30px;
							margin-bottom: 5px;

							@media (max-width:992px) {
								font-size: 20px;
							}*/
						}

						span {
							display: block;

							@media (max-width:992px) {
								display: none;
							}
						}
					}

					.fav-count {
						position: relative;

						.sub-count {
							-moz-border-radius: 50%;
							-webkit-border-radius: 50%;
							border-radius: 50%;
							width: 15px;
							height: 15px;
							right: 38px;
							top: 2px;
							position: absolute;
							display: block;
							text-align: center;
							background-color: #E32f2f;
							border-radius: 100%;
							font-size: 9px;
							line-height: 15px;
							color: #fff;

							@media (max-width:992px) {
								right: 7px;
								top: -1px;
							}

							@media (max-width:575px) {
								right: 0px;
								top: -5px;

							}
						}
					}
				}
			}
		}

	}

	.input-search-unit {
		padding: 5px 15px;
		max-width: 280px;

		@media (max-width:992px) {
			max-width: 100%;

		}

		@media (max-width:992px) {
			margin-bottom: 5px;
		}

		.form-control {
			padding: 5px 10px;
			width: 200px;

		}

		.form-control::placeholder {
			font-size: 0.875rem;
			color: #aaa;
			font-style: italic;
		}

		.btn {
			border-radius: 0 5px 5px 0;
			padding: 0px 15px;
		}
	}

	.top-nav {
		padding: 3px 0 8px;
		text-align: right;
		font-size: var(--top-nav-font-size);
		font-family: var(--bodyfont);
		background-color: var(--top-nav-bg-color);

		ul {
			padding: 0;
			margin: 0;
			display: inline-flex;
			align-items: center;
			flex-wrap: wrap;
			justify-content: flex-end;

			li {
				display: inline-block;
				position: relative;
				font-size: var(--top-nav-font-size);
				color: var(--top-nav-font-color);

				@media (max-width:992px) {
					display: block;
				}

				a {
					color: var(--top-nav-font-color);
					font-size: var(--top-nav-font-size);
					text-decoration: none;
					padding: 5px 15px;

					@media (max-width:550px) {
						padding: 5px 4px;
					}

					&:hover {
						color: var(--top-nav-font-color-hover)
					}

					i.fa {
						font-size: var(--top-nav-icon-size);
						margin-right: 5px;
					}
				}
			}
		}



	}





}