.unit-title-v1 {
	display: flex;
	flex-wrap: wrap;

	h1 {
		position: relative;
		margin-right: 15px;
		padding-right: 30px;

		.fa {
			font-size: 18px;
			position: absolute;
			right: 0;
			top: 0;
			font-weight: 600;

			&.fa-heart {
				color: #E32f2f;
			}
		}
	}

	.stars {
		color: var(--color-stars);
		font-size: var(--font-size-md);
		vertical-align: top;
	}
}