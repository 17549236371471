.unit-booking-v1 {
	.travel-data {

		padding: 10px;
		background-color: var(--color-grey-normal);
		scroll-margin: var(--scroll-margin);

		.travel-field {
			align-self: baseline;
			margin-bottom: 5px;

			@media(max-width: 991px) {
				padding-bottom: 5px;
			}
		}

		.travel-period-input {
			background-image: url(RESOURCE/img/icon-kalender.svg);
			padding-left: 25px;
			border: none;
			min-width: 270px;
			background-size: 18px;
			background-position: 0px 0px;
			background-repeat: no-repeat;
			background-color: transparent;
		}

		.label {
			min-width: 120px;
			display: inline-block;
		}

		.warn {
			font-weight: 700;
		}

		.reset-btn {
			@media(max-width: 991px) and (min-width: 600px) {
				float: right;
			}

			@media(max-width:600px) {
				margin-left: 125px;
			}

			@media(max-width:460px) {
				margin-left: inherit;
			}

		}

		.form-control {
			text-align: left;

			&.custom {
				display: inline-block;
				width: 200px;
				font-size: var(--font-size-md);
				cursor: pointer;

				&.warn {
					border: 1px solid var(--color-red);
				}
			}
		}

		.guest-box {

			position: relative;
			max-width: 200px;
			display: inline-block;

			.fa-chevron-down {
				position: absolute;
				right: 10px;
				top: 10px;
				pointer-events: none;
			}
		}
	}

	.search-bl {
		position: relative;

		.fa {
			position: absolute;
			right: 0;
			top: 15px;
			color: var(--color-grey-dark);
			pointer-events: none;
		}
	}

	.form-control {
		border: none;
		border-bottom: 1px solid #e6e6e6;
		text-align: left;
		margin-bottom: 15px;
		background-color: var(--color-white);
		font-size: var(--font-size-md);
		cursor: pointer;
	}

	.table {
		td {
			border-top: 2px solid var(--color-white);
		}

		tbody {
			border-top: 2px solid var(--color-white);
		}
	}

	.optionals {
		margin-top: 20px;
	}

	.optionals-title {
		font-size: var(--h4-font-size);
		font-weight: var(--h4-font-weight);
		padding-left: 10px;
	}

	.consumptioncosts-title {
		font-size: var(--h5-font-size);
		font-weight: var(--h5-font-weight);
		padding-left: 10px;
	}

	.button-row {
		text-align: right;
		margin-top: 20px;

	}

	.subrow {
		margin-top: 20px;
	}

	.btn-option {
		margin-top: 10px;
	}
}