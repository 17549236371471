.search-unit-v1 {
	box-shadow: 0px 1px 11px 1px rgb(133 147 179 / 30%);

	&:hover {
		box-shadow: 0 2px 11px 1px rgb(0 0 0 / 20%);
	}

	.unit-row {
		background-color: #fff;
	}

	.img-col {
		position: relative;
		overflow: hidden;
		margin-left: 15px;
		height: 100%;

		@media (max-width: 1200px) {
			margin-right: 15px;
		}

		.item {
			position: relative;
			padding-top: 67%;
		}



		img {
			-o-object-fit: contain;
			object-fit: contain;
			-o-object-position: center center;
			object-position: center center;
			height: 100%;
			position: absolute;
			top: 0;
			width: 100%;

		}

		.owl-nav {
			button {
				width: 40px;
				height: 80px;
				left: auto;
				margin: 0;
				padding: 0;
				-webkit-box-shadow: 0 0 0 0;
				box-shadow: 0 0 0 0;
				border: 1px solid #ccc;
				border-radius: 4px;
				position: absolute;
				background: hsla(0, 0%, 100%, .75);
				border: none;
				color: #333;
				top: 50%;
				width: 40px;
				height: 80px;
				transform: translateY(-50%);
				transition: all 0.25s ease 0s;
				border: 1px solid #ccc;

				span {
					display: none;
				}

				&:before {
					content: "";
					border: solid black;
					border-width: 0 3px 3px 0;
					display: inline-block;
					padding: 6px;
				}

				&:hover {
					background: #fff;
					cursor: pointer;
				}
			}

			.owl-prev {
				left: -40px;
				-webkit-border-top-right-radius: 4px;
				-webkit-border-bottom-right-radius: 4px;
				-moz-border-radius-topright: 4px;
				-moz-border-radius-bottomright: 4px;
				border-top-right-radius: 4px;
				border-bottom-right-radius: 4px;
				border-top-left-radius: 0;
				border-bottom-left-radius: 0;

				&:before {
					content: "";
					transform: rotate(135deg);
					-webkit-transform: rotate(135deg);
				}
			}

			.owl-next {
				right: -40px;
				-webkit-border-top-left-radius: 4px;
				-webkit-border-bottom-left-radius: 4px;
				-moz-border-radius-topleft: 4px;
				-moz-border-radius-bottomleft: 4px;
				border-top-left-radius: 4px;
				border-bottom-left-radius: 4px;
				border-top-right-radius: 0;
				border-bottom-right-radius: 0;

				&:before {
					content: "";
					transform: rotate(-45deg);
					-webkit-transform: rotate(-45deg);
				}
			}
		}

		.owl-dots {
			position: absolute;
			bottom: 10px;
			left: 0;
			right: 0;
			margin: 0 auto;
			text-align: center;

			.owl-dot {
				height: 6px;
				width: 6px;
				background: #333 !important;
				opacity: 1;
				margin: 1px;
				border-radius: 50%;
				opacity: .6;

				&.active {
					background: #ffffff !important;
					opacity: 1;
				}
			}
		}

		.owl-carousel {
			&:hover {
				.owl-prev {
					left: -1px;
				}

				.owl-next {
					right: -1px;
				}

			}
		}
	}

	.search-unit-right {

		@media (min-width:768px) {
			display: flex;
			justify-content: space-between;
		}

		.search-price {
			padding: 16px;
			display: flex;
			justify-content: space-between;

			@media (min-width:768px) {
				flex-direction: column;
				width: 35%;
			}

			@media (max-width:767px) {
				align-items: center;
			}

			.unit-act-row {
				display: flex;
				justify-content: end;
			}

			.btn-primary {
				position: relative;
				width: 137px;
				height: 40px;
				text-align: left;
				padding-top: 7px;

				&:before {
					content: "\f105";
					font-family: Font Awesome\ 5 Free;
					font-weight: 900;
					position: absolute;
					top: 8px;
					right: 10px;
				}
			}

			.cta-search-unit {
				display: flex;
				flex-direction: column;
				align-items: end;

				.rating {
					margin-bottom: 10px;

					.fa,
					span {
						color: #95a7bf;
					}
				}
			}
		}

		.info-col {
			padding: 20px;
			display: inline-block;
			padding-left: 0;

			@media (min-width:768px) {
				width: 65%;
			}

			@media (max-width:992px) {
				padding-left: 20px;
			}

			.unit-title {
				&.h3 {
					font-size: 30px;
				}

				&:hover {
					color: #95A7BF;
				}

			}

			.unit-place {
				padding-bottom: 5px;
				font-size: 12px;
				color: #656565;
			}

			.properties-row {
				margin-top: 9px;
				font-size: 14px;
				line-height: 24px;

				.rentRate {
					strong {
						font-weight: 600;
						font-size: 22px;
					}
				}

				&.properties-bl {
					margin-top: 40px;
					display: flex;
					justify-content: space-between;
					align-items: center;

					.unit-properties {
						margin: 0;
					}
				}
			}
		}
	}





	.tick-li {

		li {
			font-size: var(--font-size-md);
			padding-left: 20px;
			padding-right: 20px;
			position: relative;
			display: inline-block;

			&:before {
				content: "\f00c";
				font-family: "Font Awesome 5 Free";
				-webkit-font-smoothing: antialiased;
				font-weight: 600;
				display: inline-block;
				position: absolute;
				left: 0;
				top: 0;
				color: var(--color-primary);
			}
		}

	}

	.fav-icon,
	.fav-element {
		width: 35px;
		height: 35px;
		border-radius: 50%;
		background-color: #fff;
		position: absolute;
		right: 15px;
		top: 15px;
		cursor: pointer;
		z-index: 1;

		.fa-heart-o {
			color: #000;

			font-weight: 900;
		}

		.fa-heart {
			color: #E32f2f;

		}

		.fa-heart-o,
		.fa-heart {
			display: flex;
			justify-content: center;
			align-items: center;
			padding-top: 10px;
		}

	}

	.properties-row {}

	.unit-properties {
		display: flex;
		align-items: center;

		li {
			display: inline-flex;
			margin-right: 8px;
			/*padding: 3px;*/

			.icon {
				background-repeat: no-repeat;
				display: inline-block;
				background-position: center;
				background-size: contain;
				height: 20px;

				&.icon-balcony {
					background-image: url(RESOURCE/img/icon-balkon.svg);
					width: 26px;
				}

				&.icon-pets {
					background-image: url(RESOURCE/img/icon-pets.svg);
					width: 20px;
				}

				&.icon-sauna {
					background-image: url(RESOURCE/img/icon-sauna.svg);
					width: 20px;

				}

				&.icon-parking {
					background-image: url(RESOURCE/img/icon-parken.svg);
					width: 18px;
				}

				&.icon-terrace {
					background-image: url(RESOURCE/img/icon-terrasse.svg);
					width: 25px;
				}

				&.icon-wifi {
					background-image: url(RESOURCE/img/icon-wlan.svg);
					width: 25px;
				}

			}



			/*.fa {
				width: 30px;

				.fa-stack {
					margin-left: -10px;
					line-height: 1.4em;
				}
			}*/
		}
	}

	.unit-price-col {
		display: table;

		.unit-act-row {
			display: table-cell;
			vertical-align: bottom;

		}
	}

	.rent {
		text-align: center;
	}

	.small {
		font-size: var(--font-size-md);

		@media (min-width: 1201px) {
			&:after {
				content: "\A";
			}
		}
	}

	.main-price {
		font-weight: 600;
		text-decoration: line-through;
	}

	.discount-price {
		font-weight: 600;

		.reducedprice {
			color: var(--color-red);
		}
	}

	.prices {
		text-align: center;

		@media (max-width: 1200px) {
			text-align: right;
		}

	}

	.offer-box {
		padding-top: 15px;
		padding-bottom: 15px;
		position: relative;
		display: flex;
		margin: 0;
		align-items: center;
		flex-wrap: wrap;
		background-color: #fff;
		color: var(--color-black);
		border-top: 1px dashed var(--color-grey-normal);
		border-bottom: 1px dashed var(--color-grey-normal);
		margin-top: 15px;

		@media (max-width:1200px) {
			display: block
		}

		.offer-left {
			background: var(--color-secondary-light);
			color: var(--color-black);
			padding: 10px 15px;
			border: 1px dashed var(--color-primary);
			font-size: 16px;
			text-transform: uppercase;
			display: flex;
			align-items: center;
			margin-right: 15px;

			@media (max-width:1200px) {
				display: block;
				text-align: center;
				margin-right: 0;
				margin-bottom: 15px
			}
		}

		ul {
			padding-top: 8px;
			list-style: none;
			margin: 0;

			li {
				margin: 0 0 5px;
				line-height: 14px;
				font-size: 14px
			}
		}
	}

	.alternatives {
		margin-top: 15px;
		padding-top: 10px;
		padding-left: 10px;
		padding-right: 10px;
		padding-bottom: 1px;
		background-color: var(--color-grey-light);

		.altheading {
			font-weight: 600;
			color: var(--color-red);

		}



		a {
			color: var(--font-color-main);
			text-decoration: underline !important;

			&:hover {
				text-decoration: none !important;
				color: var(--color-red);
			}


		}

		.fa {
			color: var(--color-red);
			font-size: 12px;
			line-height: 16px;
		}

		.invalid {
			text-decoration: line-through;
		}

		.special {
			color: var(--color-red);
			font-weight: 600;
		}

	}



}