.unit-image-gallery-v3 {
	margin-right: -5px;
	margin-left: -5px;
	margin-top: -5px;

	.unit-gallery-v3 {
		.container {
			max-width: 1400px;
		}
	}

	.visible-print {
		display: none !important;
	}

	.unitGallery {
		a {
			display: none;
			padding: 5px;
			height: 150px;

			&:nth-child(2),
			&:nth-child(3),
			&:nth-child(4),
			&:nth-child(5) {
				height: 220px;
			}

			@media (max-width: 767px) {
				padding: 5px;
			}

			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}

			&:nth-child(1) {
				width: 50%;
				float: left;
				display: block;
				height: 440px;
				overflow: hidden;

				@media (max-width: 767px) {
					width: 100%;
					height: 300px;
				}
			}

			&:nth-child(2),
			&:nth-child(3),
			&:nth-child(4),
			&:nth-child(5) {
				width: 25%;
				float: left;
				display: block;

				@media (max-width: 767px) {
					width: 50%;
					height: 140px;
				}
			}

			/*&:nth-child(4) {
				clear: both;

				@media (max-width: 767px) {
					clear: none;
					height: 140px;
				}
			}*/


			/*	&:nth-child(6),
			&:nth-child(7),
			&:nth-child(8) {
				display: block;
				width: 20%;
				float: left;

				@media (max-width: 767px) {
					width: 50%;
					height: 140px;
				}
			}*/

			&:nth-child(1) {
				position: relative;

				&:before {
					content: "Fotos";
					position: absolute;
					left: 35px;
					bottom: 26px;
					margin-top: 0;
					z-index: 1;
					padding: 0;
					font-size: 14px;
					color: #000;
					font-weight: 400;
					background-color: hsla(0, 0%, 100%, .7);
					border-radius: 3px;
					width: 90px;
					height: 33px;
					padding-left: 37px;
					padding-top: 6px;

					&:hover {
						background-color: hsla(0, 0%, 100%, 1);
					}
				}

				&:after {

					content: "";
					position: absolute;

					width: 15px;
					height: 11px;
					bottom: 37px;
					top: auto;
					left: 48px;
					background: url(RESOURCE/img/img_icon.svg) no-repeat 0 0;
					background-size: cover;
					z-index: 9;
					opacity: 0.6;


					/*@media (max-width: 767px) {
						top: 5px;
						bottom: 5px;
					}*/
				}

			}
		}
	}

}