.blog-card-v1 {
	margin-bottom: 20px;
	width: 100%;
	box-shadow: 0 0.1rem 1rem rgb(0 0 0 / .1);
	background-color: var(--color-white);

	/*&:hover {
		box-shadow: 0 4px 25px 0 rgb(0 0 0 / 50%);
		-webkit-transform: translateX(1px);
		transform: translateX(1px);
	}*/

	.image {
		height: 250px;
		overflow: hidden;
		position: relative;

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}

	.blog-body {
		padding: 10px;

		p {
			font-size: 14px;
		}

		.title {
			white-space: nowrap;
			text-overflow: ellipsis;
			overflow: hidden;
			font-weight: 600;
			font-size: var(--blog-card-title-font-size);
			color: var(--blog-card-title-font-color);
			margin-bottom: 10px;


			@supports (-webkit-line-clamp: 2) {
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: initial;
				display: -webkit-box;
				-webkit-line-clamp: 2;
				-webkit-box-orient: vertical;
			}
		}
	}

	.date {
		position: absolute;
		font-size: 0.85rem;
		background-color: var(--color-primary);
		padding: 2px 5px;
		color: var(--color-white);
		bottom: 10px;
		right: 10px;

	}

	/*.title {
		font-size: var(--blog-card-title-font-size);
		color: var(--blog-card-title-font-color);
		min-height: 60px;
		font-weight: 600;
		overflow: hidden;
	}*/

	.link {
		text-align: right;
	}
}