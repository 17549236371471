.unit-navbar-v1 {
	background-color: var(--unit-navbar-bg-color);
	display: none;
	z-index: 14;
	top: 0;
	background-color: #fff;
	-webkit-box-shadow: 0 5px 8px 0 rgb(0 0 0 / 15%);
	box-shadow: 0 5px 8px 0 rgb(0 0 0 / 15%);

	&.shownavbar {
		position: -webkit-sticky;
		position: sticky;

		@media (min-width: 993px) {
			display: block;
		}
	}


	.flex-navbar-items {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin: 0 -15px;

		.btn {
			font-size: 14px;
		}
	}

	.fl-menu-itm {
		padding: 14px 20px;

		font-weight: bold;
		color: #000;
		font-size: 14px;

		&:hover,
		&.active {
			background-color: #E9F0FA;
			text-decoration: none;
		}

		@media (max-width:1200px) {
			font-size: var(--font-size-md);
		}

	}


	@media (max-width:992px) {
		display: none;
	}
}