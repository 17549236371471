.copyright-bl {
	padding-top: 15px;
	padding-bottom: 15px;

	.list-inline {
		li {
			display: inline-block;
			margin: 0 1em 0 0;

			a {
				font-size: 13px;
				color: #333;
			}
		}
	}
}

.footer-v1 {
	padding-top: 50px;
	padding-bottom: 50px;
	margin-top: 70px;
	background-color: #F4F7FA;
	color: #0D0D0D;

	@media (max-width:767px) {
		text-align: center;
		margin: 0;
	}




	.foot-contact-text {
		margin-bottom: 12px;

		.footer-tel {
			color: #4C5973;

			&:hover {
				text-decoration: underline;
			}
		}
	}


	.headline {
		font-size: 24px;
		color: var(--footer-font-color);
		border-bottom: 1px dotted var(--color-black);
		margin-bottom: 20px;
		padding-bottom: 10px;
		text-transform: uppercase;
	}

	.socialBar {
		a {
			font-size: 27px;
			margin-right: 20px;
			color: var(--color-primary);

			&:hover {
				color: #95A7BF;
			}
		}
	}

	.list-unstyled {
		li {
			a {
				margin-bottom: 5px;
				padding-bottom: 5px;
				font-size: 13px;
				font-weight: normal;
				padding-left: 15px;
				display: inline-block;
				position: relative;
				color: #4C5973;

				&:hover {
					text-decoration: underline;
				}

				&:before {
					content: "";
					background-image: url(RESOURCE/img/arrow-right.svg);
					height: 7px;
					width: 5px;
					top: 6px;
					position: absolute;
					left: 0;
					display: block;
					background-size: contain;
					background-repeat: no-repeat;
				}


			}
		}
	}

	.main-footer {

		background-color: var(--footer-bg-color);
		color: var(--footer-font-color);
		border-top: 1px solid var(--footer-border-top-color);

		padding-left: 5vw;
		padding-right: 5vw;

		position: relative;

		.headline {
			font-size: 16px;
			color: var(--footer-font-color);
			font-weight: bold;
			text-transform: uppercase;
		}

		.v-office-logo {

			@media(min-width: 993px) {
				text-align: right;
			}

			@media(max-width: 993px) {
				text-align: left;
			}
		}


		.logo {
			width: 250px;
			height: auto;
			margin-top: -40px;
			padding-bottom: 20px;
		}

		.openings {
			font-style: italic;

			div {
				padding-top: 10px;
			}
		}

		a {
			color: var(--footer-font-color);
		}

		.social-icons {

			li {

				a {
					color: var(--footer-font-color);
				}

				.fa {
					font-size: 20px;
					color: var(--footer-font-color);

				}
			}

		}




		#button-addon1 {
			color: #ffc371;
		}

		.btn-link {
			background-color: var(--footer-btn-bg-color);

			.fa {
				color: var(--footer-icon-color);
			}
		}

		i {
			color: #ffc371;
		}

		.form-control::placeholder {
			font-size: 0.95rem;
			color: #aaa;
			font-style: italic;
		}

		.form-control.shadow-0:focus {
			box-shadow: none;
		}
	}

	.copyright {
		background-color: var(--footer-copyright-bg-color);
		color: var(--footer-copyright-font-color);

		a {
			color: var(--footer-copyright-font-color);
			text-decoration: none;

			&:hover {
				text-decoration: underline;
			}
		}

		.v-office-logo {
			float: left;
			padding-right: 20px;

			img {
				width: 50px;
			}
		}
	}

}