.unit-description-v1 {
	.description {
		/*margin-bottom: 30px;*/

		p {
			font-size: 18px;
			line-height: 1.5;
		}
	}

	.show-more {
		font-size: var(--font-size-md);
	}
}