.unit-pricelist-v1 {

	.nav-link {
		border: 1px solid var(--color-grey-normal);
		border-bottom: none;
		background-color: var(--color-grey-light);
		color: var(--font-color-main);
		text-decoration: none;

		&.active {
			background-color: var(--color-white);
			border: 1px solid var(--color-grey-dark);
			border-bottom: none;
		}
	}

	.nav-item {
		margin-right: 5px;
	}

	.hint {
		margin: 25px 0px;
	}

	.pricelist {
		.mainrow {

			td {
				font-weight: 500;

				@media (max-width:420px) {
					font-size: 0.9rem;
				}
			}
		}

		.subrow {

			td {
				vertical-align: bottom;

				@media (max-width:420px) {
					font-size: 0.9rem;
				}
			}
		}
	}

	.showLess {
		position: relative;

		&.showFull {
			.widget {

				.widget-block {
					height: auto;
					overflow: visible;
				}
			}


			.hide-show-btn {

				&:after {
					content: "\f106";
				}
			}
		}

		.widget {

			.widget-block {
				height: 353px;
				overflow: hidden;
			}
		}

		.first {
			display: block;
		}

		.hide-show-btn {
			position: absolute;
			bottom: -14px;
			left: 50%;
			transform: translate(-50%, 0);
			padding: 5px 15px;
			background-color: var(--white);
			display: inline-block;
			border: 1px solid var(--btn-border-color-hide-show);
			font-size: var(--font-size-md);

			&:focus,
			&:hover {
				text-decoration: none;
			}

			&:after {
				content: "\f107";
				margin-left: 10px;
				margin-left: 5px;
				font-family: fontawesome;
			}
		}
	}
}