.themes-v1 {
	position: relative;

	&:after {
		content: "";
		background-color: var(--color-grey-normal);
		width: 100%;
		position: absolute;
		z-index: 0;
		height: 100%;
		bottom: 0;

		@media (min-width:993px) {
			height: 400px;
			bottom: -25px;
		}
	}

	.themes-group {
		position: relative;
		z-index: 1;
	}

	.theme-unit {
		padding-bottom: 20px;
		background-color: #FFF;
		box-shadow: 0px 3px 10px #0000001A;
		position: relative;
		margin-bottom: 15px;

		&:hover {
			box-shadow: 0px 10px 20px #00000031;
		}

		.link {
			position: absolute;
			inset: 0;
			z-index: 1;
		}

		&:hover {
			.theme-img {
				img {
					transform: scale(1.2);
				}
			}
		}


		.theme-img {
			margin-bottom: 12px;
			height: 250px;
			overflow: hidden;

			img {
				transition: all .5s ease-in-out;
				transform: scale(1);
				object-fit: cover;
				width: 100%;
				height: 100%;
			}
		}

		h2 {
			margin-top: 0;
			margin-bottom: 0;
			padding: 6px 15px 10px;
			font-size: 24px;
			text-transform: uppercase;
		}

		p {
			margin-bottom: 20px;
			padding: 0 15px;
		}

		.icon-arrow {
			background-image: url(RESOURCE/img/arrow-right.svg);
			background-size: contain;
			display: inline-block;
			position: absolute;
			bottom: 15px;
			right: 10px;
			width: 15px;
			height: 15px;
			filter: brightness(0) saturate(100%) invert(65%) sepia(16%) saturate(443%) hue-rotate(176deg) brightness(92%) contrast(95%);
		}
	}

	/*display: grid;
	grid-template-columns: repeat(6, 1fr);
	grid-auto-rows: minmax(150px, auto);
	grid-gap: 1em;
	grid-template-areas:
		'img1 img1 img2 img2 img3 img3'
		'img1 img1 img2 img2 img3 img3'
		'img4 img4 img2 img2 img5 img5'
		'img4 img4 img2 img2 img5 img5';


	@media (max-width: 992px) {
		display: flex;
		flex-direction: column;
	}

	a {
		text-decoration: none
	}


	.img1 {
		grid-area: img1;
		background-image: url(RESOURCE/img/grid-hund.jpg);
	}

	.img2 {
		grid-area: img2;
		background-image: url(RESOURCE/img/grid-strandkoerbe.jpg);
	}

	.img3 {
		grid-area: img3;
		background-image: url(RESOURCE/img/grid-kind.jpg);
	}

	.img4 {
		grid-area: img4;
		background-image: url(RESOURCE/img/grid-segeln.jpg);
	}

	.img5 {
		grid-area: img5;
		background-image: url(RESOURCE/img/grid-luftbild.jpg);
	}

	[class^='theme-card'] {
		color: white;
		text-decoration: none;
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center;
		padding: 10px;

		@media (max-width: 992px) {
			height: 35vh;
		}


		&:hover {
			text-decoration: none;
		}

		h2 {
			font-size: var(--theme-card-title);
			color: var(--theme-card-text-color);
		}

		p {
			font-size: var(--theme-card-description);
			color: var(--theme-card-text-color);
		}

		a {
			text-decoration: none;
		}
	}

	.theme-card-1 {
		min-width: 100px;
		min-height: 100px;
		position: relative;
		display: block;
		width: 100%;
		height: 100%;
		background-color: rgba(0, 0, 0, 0.55);
		background-blend-mode: multiply;
		transition: ease-out 0.5s;
	}

	.theme-card-2 {
		min-width: 100px;
		min-height: 100px;
		position: relative;
		width: 100%;
		height: 100%;
		color: white;
		text-decoration: none;
		font-size: 1.2em;
		transition: ease-out 0.5s;

		display: flex;
		align-items: center;
		justify-content: center;
	}



	.theme-card-3 {
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		padding: 0;
	}

	.theme-card-3--description-container {
		background-color: var(--overlay-color);
		padding: 10px;
		transition: 1s;
	}



	@media (min-width: 992px) {
		.theme-card-1--description-container .description {
			opacity: 0;
			transition: ease-out 0.5s;
		}

		.theme-card-1:hover .description {
			opacity: 1;
			transition: ease-in 0.5s;
		}

		.theme-card-1:hover {
			background-color: rgba(0, 0, 0, 0.55);
			background-blend-mode: multiply;
			transition: ease-in 0.5s;
		}

		.theme-card-1:not(hover) {
			background-color: initial;
			background-blend-mode: multiply;
			transition: ease-out 0.5s;
		}

		.theme-card-2:hover {
			background-color: var(--overlay-color);
			background-blend-mode: multiply;
			transition: ease-in 0.5s;
		}

		.theme-card-2:not(hover) {
			background-color: initial;
			background-blend-mode: multiply;
			transition: ease-out 0.5s;
		}

		.theme-card-3--description-container {
			height: 15%
		}

		.theme-card-3--description-container .title {
			text-align: center;
			padding: 0px 10px;
		}

		.theme-card-3--description-container .description {
			padding: 30px;
		}

		.theme-card-3:hover .theme-card-3--description-container {
			height: 100%;
			transition: 1s;
		}

		.theme-card-3 .theme-card-3--description-container>p {
			opacity: 0;
			display: none;
		}

		.theme-card-3:hover .theme-card-3--description-container>p {
			opacity: 1;
			transition: 1s ease-in;
			display: block;
		}
	}

	@media (max-width: 991px) {

		.theme-card-1--description-container,
		.theme-card-2--description-container,
		.theme-card-3--description-container {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			text-align: center;
			padding: 30px;

		}
	}*/
}