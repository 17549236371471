@font-face {
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 300;
	src: local(''),
		url('RESOURCE/fonts/poppins-v15-latin-300.woff2') format('woff2'),
		url('RESOURCE/fonts/poppins-v15-latin-300.woff') format('woff');
	font-display: swap;
}

/* poppins-regular - latin */
@font-face {
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 400;
	src: local(''),
		url('RESOURCE/fonts/poppins-v15-latin-regular.woff2') format('woff2'),
		url('RESOURCE/fonts/poppins-v15-latin-regular.woff') format('woff');
	font-display: swap;
}

/* poppins-500 - latin */
@font-face {
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 500;
	src: local(''),
		url('RESOURCE/fonts/poppins-v15-latin-500.woff2') format('woff2'),
		url('RESOURCE/fonts/poppins-v15-latin-500.woff') format('woff');
	font-display: swap;
}

/* poppins-600 - latin */
@font-face {
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 600;
	src: local(''),
		url('RESOURCE/fonts/poppins-v15-latin-600.woff2') format('woff2'),
		url('RESOURCE/fonts/poppins-v15-latin-600.woff') format('woff');
	font-display: swap;
}

/* poppins-700 - latin */
@font-face {
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 700;
	src: local(''),
		url('RESOURCE/fonts/poppins-v15-latin-700.woff2') format('woff2'),
		url('RESOURCE/fonts/poppins-v15-latin-700.woff') format('woff');
	font-display: swap;
}

/* BebasNeue-Regular */
@font-face {
	font-family: 'BebasNeue';
	font-style: normal;
	font-weight: 400;
	src: local(''),
		url('RESOURCE/fonts/BebasNeue-Regular.woff2') format('woff2'),
		url('RESOURCE/fonts/BebasNeue-Regular.woff') format('woff');
	font-display: swap;
}

/* OpenSans-Bold */
@font-face {
	font-family: 'OpenSans';
	font-style: Bold;
	font-weight: 700;
	src: local(''),
		url('RESOURCE/fonts/OpenSans-Bold.woff2') format('woff2'),
		url('RESOURCE/fonts/OpenSans-Bold.woff') format('woff');
	font-display: swap;
}

/* OpenSans-Regular */
@font-face {
	font-family: 'OpenSans';
	font-style: normal;
	font-weight: 600;
	src: local(''),
		url('RESOURCE/fonts/OpenSans-SemiBold.woff2') format('woff2'),
		url('RESOURCE/fonts/OpenSans-SemiBold.woff') format('woff');
	font-display: swap;
}

/* OpenSans-Regular */
@font-face {
	font-family: 'OpenSans';
	font-style: normal;
	font-weight: 400;
	src: local(''),
		url('RESOURCE/fonts/OpenSans-Regular.woff2') format('woff2'),
		url('RESOURCE/fonts/OpenSans-Regular.woff') format('woff');
	font-display: swap;
}